import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import withMemo from '../../../decorators/withMemo'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { selectors as AppSelectors } from '../../../redux/app/redux'
import HeaderSimple from '../../../components/HeaderSimple'
import BlockQuestionsIntroduction
  from '../../../components/BlockQuestionsIntroduction'
import NavQuestions from '../../../components/NavQuestions'
import BlockQuestion from '../../../components/BlockQuestion'
import QuestionMessage from '../../../components/QuestionMessage'
import QuestionPush from '../../../components/QuestionPush'
import QuestionPushPractice from '../../../components/QuestionPushPractice'
import QuestionPushArticle from '../../../components/QuestionPushArticle'
import QuestionPushVideo from '../../../components/QuestionPushVideo'
import QuestionPushEmpty from '../../../components/QuestionPushEmpty'
import VideoPlayerWithPlaylist
  from '../../../components/VideoPlayerWithPlaylist'
import { videoCustomPlaylistTransformer } from '../../../transformers'

import {
  answerMessageTransformer,
  blockQuestionsIntroductionTransformer,
  blockQuestionTransformer,
  headerSimpleQuestionsTransformer,
  pushHelpMessageTransformer,
  questionMessageTransformer,
  questionPushArticleExtTransformer,
  questionPushArticleIntTransformer,
  questionPushPracticeTransformer,
  questionPushTransformer,
  questionPushVideoTransformer
} from './transformers'
import {
  actions as QuestionsDuMomentActions,
  selectors as QuestionsDuMomentSelectors
} from './redux'


function QuestionsDuMomentWrapper(props) {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const variant = useSelector(PageSelectors.variant)
  const route = useSelector(AppSelectors.route)
  const questions = useSelector(QuestionsDuMomentSelectors.questions)
  const periodes = useSelector(QuestionsDuMomentSelectors.periodes)
  const currentPeriode = useSelector(QuestionsDuMomentSelectors.currentPeriode)
  const headerSimpleQuestionsProps = useMemo(() => headerSimpleQuestionsTransformer(props, t, variant, route), [props, t, variant, route])
  const blockQuestionsIntroductionProps = useMemo(() => blockQuestionsIntroductionTransformer(props, t, variant), [props, t, variant])

  const currentPath = (router.query?.slug?.split('/') ?? [])[0]
  const { locale } = router.query

  const currentPeriodeContent = useMemo(() => periodes?.find((periode) => periode.path === currentPeriode), [periodes, currentPeriode])

  useEffect(() => {
    if (currentPeriode) {
      setTimeout(() => {
        const questionNid = window?.location?.hash?.replace('#', '') ?? null

        const elementSelector = questionNid === null ? '#top_nav_questions' : `#question_nid_${questionNid}`
        const element = document.querySelector(elementSelector)

        if (element && questionNid) {
          const target = element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop) - 100

          window.scrollTo({
            top: target,
            behavior: 'smooth',
          })
        }
      }, 750)
    }
  }, [currentPeriode])

  const changeCurrentHandler = useCallback((item) => {
    dispatch(QuestionsDuMomentActions.setCurrentPeriode(item))
    dispatch(QuestionsDuMomentActions.loadQuestionsByPeriode())
  }, [dispatch])

  const changeCurrentHandlerWithScroll = useCallback((item) => {
    changeCurrentHandler(item)
  }, [changeCurrentHandler])

  const navQuestionsTopProps = useMemo(() => ({
    links: periodes.map((periode) => ({
      ...periode,
      linkProps: {
        asAnchor: true,
        route: `${locale}/${currentPath}/${periode.path}`,
        href: `${currentPath}/${periode.path}`,
      },
      changeCurrentHandler: () => changeCurrentHandler(periode.path),
      isCurrent: !!(currentPeriodeContent && currentPeriodeContent?.tid === periode?.tid),
    })),
  }), [currentPeriodeContent, periodes, locale, currentPath, changeCurrentHandler])

  const navQuestionsBottomProps = useMemo(() => ({
    links: periodes.map((periode) => ({
      ...periode,
      linkProps: {
        asAnchor: true,
        route: `${locale}/${currentPath}/${periode.path}`,
        href: `${currentPath}/${periode.path}`,
      },
      changeCurrentHandler: () => changeCurrentHandlerWithScroll(periode.path),
      isCurrent: !!(currentPeriodeContent && currentPeriodeContent?.tid === periode?.tid),
    })),
  }), [periodes, locale, currentPath, currentPeriodeContent, changeCurrentHandlerWithScroll])


  useEffect(() => () => {
    dispatch(QuestionsDuMomentActions.setPeriodes(null))
    dispatch(QuestionsDuMomentActions.setQuestions(null))
    dispatch(QuestionsDuMomentActions.setCurrentPeriode(null))
  }, [dispatch])

  return (
    <>
      <HeaderSimple {...headerSimpleQuestionsProps} />
      {!currentPeriodeContent && (
        <BlockQuestionsIntroduction {...blockQuestionsIntroductionProps} />
      )}
      <NavQuestions
        id="top_nav_questions"
        {...navQuestionsTopProps}
      />
      {questions && questions.length && (
        <>
          <>
            {questions.map((question) => (
              <BlockQuestion
                id={`question_nid_${question?.nid}`}
                key={`question_${question?.nid}`}
                {...blockQuestionTransformer(question, t, variant)}
              >
                <QuestionMessage
                  {...questionMessageTransformer(question, t, variant)}
                />
                <QuestionMessage
                  {...answerMessageTransformer(question, t, variant)}
                />
                {question?.fieldPushes && (
                <>
                  {question?.fieldPushHelp && question?.fieldPushHelp?.processed && (
                  <QuestionMessage
                    {...pushHelpMessageTransformer(question, t, variant)}
                  />
                            )}
                  <QuestionPush {...questionPushTransformer(question, t, variant)}>
                    {question.fieldPushes.map((fieldPush, index) => (
                      <React.Fragment
                        key={`question_${question?.nid}_push_${index}`}
                      >
                        {fieldPush?.entity?.__typename === 'ParagraphQuestionDuMomentExtArticle' && (
                        <QuestionPushArticle {...questionPushArticleExtTransformer(fieldPush, t, variant, index)} />
                                    )}
                        {fieldPush?.entity?.__typename === 'ParagraphQuestionDuMomentIntArticle' && (
                        <QuestionPushArticle {...questionPushArticleIntTransformer(fieldPush, t, variant, index)} />
                                    )}
                        {fieldPush?.entity?.__typename === 'ParagraphQuestionDuMomentEnPratique' && (
                        <QuestionPushPractice {...questionPushPracticeTransformer(fieldPush, t, variant, index)} />
                                    )}
                        {fieldPush?.entity?.__typename === 'ParagraphQuestionDuMomentVideo' && (
                        <QuestionPushVideo {...questionPushVideoTransformer(fieldPush, t, variant, index)} />
                                    )}
                        {fieldPush?.entity?.__typename === 'ParagraphVideoCustom' && (
                        <QuestionPushEmpty>
                          <VideoPlayerWithPlaylist
                            playlistProps={videoCustomPlaylistTransformer(fieldPush?.entity?.fieldVideoItem ?? {}, true)}
                            isInPush
                            isSmall
                          />
                        </QuestionPushEmpty>
                                    )}
                      </React.Fragment>
                              ))}
                  </QuestionPush>
                </>
                      )}
              </BlockQuestion>
                ))}
          </>
          <NavQuestions
            id="bottom_nav_questions"
            {...navQuestionsBottomProps}
          />
        </>
        )}
    </>
  )
}

export default withMemo()(QuestionsDuMomentWrapper)
